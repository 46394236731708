<template>
  <div class="Asset">
    <div class="notice">
      <img @click="openNotice" src="@/assets/images/icon_Notes.png" alt="">
    </div>
    <div style="overflow-x: auto; width: 100%">
      <div class="head">
        <div class="head-item" :class="{'head-item-active': pageType == 2}" @click="changeStatus(2)">
          <div class="hi-text">{{$t('asset.liquid')}}</div>
          <div class="hi-op"></div>
        </div>
        <div class="head-item" :class="{'head-item-active': pageType == 1}" @click="changeStatus(1)">
          <div class="hi-text">{{$t('asset.mining')}}</div>
          <div class="hi-op"></div>
        </div>
        <div class="head-item" :class="{'head-item-active': pageType == 0}" @click="changeStatus(0)">
          <div class="hi-text">{{$t('asset.network')}}</div>
          <div class="hi-op"></div>
        </div>
        <div class="head-item" :class="{'head-item-active': pageType == 4}" @click="changeStatus(4)">
          <div class="hi-text">{{$t('asset.reward')}}</div>
          <div class="hi-op"></div>
        </div>
        
      </div>
    </div>
    
    <!-- <div class="main" :class="pageType == 1 ? 'main1' : pageType == 0 ? 'main2' : 'main3'"> -->
    <div class="main" :class="pageType == 1 ? 'main1' : (pageType == 4 ? 'main4' : (pageType == 0 ? 'main2' : 'main3'))">
      <template v-if="pageType == 1">
        <div class="main-float" @click="toDaily">{{$t('asset.daily')}}</div>
        <div class="main-award">
          <div class="ma-key">{{$t('asset.total')}}({{systemInfo.tkname}})</div>
          <div class="ma-value">{{assetInfo.rewardAll}}</div>
        </div>
        <div class="main-yesterday">
          <div class="my-key">{{$t('asset.earn')}}</div>
          <div class="my-num" :class="{'color-up': +assetInfo.reward > 0}">+{{assetInfo.reward}}</div>
          <div class="my-rate" :class="{'my-rate-up': +assetInfo.rate > 0}">{{assetInfo.rate}}%</div>
        </div>
        <div class="main-yesterday" v-if="assetInfo.error && assetInfo.error != 0">
          <div class="my-key">{{$t('asset.error')}}</div>
          <!-- <div class="my-num" :class="{'color-up': assetInfo.error > 0, 'color-down': assetInfo.error < 0}">{{assetInfo.error > 0 ? '+' + assetInfo.error : assetInfo.error}}</div> -->
          <div class="my-num">{{assetInfo.error > 0 ? '+' + assetInfo.error : assetInfo.error}}</div>
          <img class="my-icon" @click="openDetail" src="@/assets/images/icon_Notes_red.png" alt="">
        </div>
        <div class="main-list main-list-col">
          <div class="main-row">
            <div class="main-item">
                <div class="mi-key">{{$t('asset.withdraw_1')}}({{systemInfo.tkname}})</div>
                <div class="mi-value">{{award}}</div>
            </div>
            <div class="main-btn">
              <CommonBtn :btnText="$t('asset.btn_2')" :btnType="2"  @btnClick="openWithdraw(0)"></CommonBtn>
            </div>
          </div>
          <div class="main-row">
            <div class="main-item">
                <div class="mi-key">{{$t('asset.withdraw_2')}}({{systemInfo.tkname}})</div>
                <div class="mi-value">{{pledgeNum[0]}}</div>
            </div>
            <div class="main-btn">
              <CommonBtn :btnText="$t('asset.btn_2')" :btnType="2"  @btnClick="withdraw"></CommonBtn>
            </div>
          </div>
          <div class="main-row">
            <div class="main-item">
                <div class="mi-key">{{$t('asset.pledge')}}({{systemInfo.tkname}})</div>
                <!-- <div class="mi-value">{{pledge}}</div> -->
                <div class="mi-value">{{pledgeNum[1]}}</div>
            </div>
            <div class="main-btn">
              <CommonBtn :btnText="$t('common.myPledge')" :btnType="2"  @btnClick="toRecord"></CommonBtn>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="pageType == 2">
        <div class="main-float" @click="toDaily">{{$t('asset.daily')}}</div>
        <div class="main-award">
          <div class="ma-key">{{$t('asset.total')}}({{systemInfo.tkname}})</div>
          <div class="ma-value">{{assetInfo.rewardAll}}</div>
        </div>
        <div class="main-yesterday">
          <div class="my-key">{{$t('asset.earn')}}</div>
          <div class="my-num" :class="{'color-up': +assetInfo.reward > 0}">+{{assetInfo.reward}}</div>
          <div class="my-rate" :class="{'my-rate-up': +assetInfo.rate > 0}">{{assetInfo.rate}}%</div>
        </div>
        <div class="main-yesterday" v-if="assetInfo.error && assetInfo.error != 0">
          <div class="my-key">{{$t('asset.error')}}</div>
          <!-- <div class="my-num" :class="{'color-up': assetInfo.error > 0, 'color-down': assetInfo.error < 0}">{{assetInfo.error > 0 ? '+' + assetInfo.error : assetInfo.error}}</div> -->
          <div class="my-num">{{assetInfo.error > 0 ? '+' + assetInfo.error : assetInfo.error}}</div>
          <img class="my-icon" @click="openDetail" src="@/assets/images/icon_Notes_red.png" alt="">
        </div>
        <div class="main-list main-list-col">
          <div class="main-row">
            <div class="main-item">
                <div class="mi-key">{{$t('withdraw.extracted')}}</div>
                <div class="mi-value">{{orderData.overQuote}} {{systemInfo.tkname}} | {{ orderData.overUsdt }} USDT</div>
            </div>
            <div class="main-btn" style="margin-left: 4px;">
              <CommonBtn :btnText="$t('asset.btn_2')" :btnType="2"  @btnClick="toRecord"></CommonBtn>
            </div>
          </div>
          <div class="main-row">
            <div class="main-item">
                <div class="mi-key">{{$t('asset.pledge')}}</div>
                <div class="mi-value">{{orderData.quote}} {{systemInfo.tkname}} | {{ orderData.usdt }} USDT</div>
            </div>
          </div>
        </div>
        <div class="success-btns">
          <div class="success-btn">
            <CommonBtn :btnText="$t('common.toPledge')" @btnClick="toPledge"></CommonBtn>
          </div>
          <div class="success-btn" @click="toHome">
            <CommonBtn :btnText="$t('common.myPledge')" @btnClick="toRecord" :btnType="2"></CommonBtn>
          </div>
        </div>
      </template>
      <template v-else-if="pageType==0">
        <div class="main-award">
          <div class="ma-key">{{$t('asset.surplus')}}({{systemInfo.tkname}})</div>
          <div class="ma-value">{{networkTotal}}</div>
        </div>
        <div class="main-item" style="display: flex;">
          <div class="mi-key" style="margin-right: 10px;">{{$t('asset.initial')}}({{systemInfo.tkname}})</div>
          <div class="mi-value">{{assetInfo.all}}</div>
        </div>
        <div class="main-list main-list-col">
          <div class="main-row">
            <div class="main-item">
                <div class="mi-key">{{$t('asset.angle')}}({{systemInfo.tkname}})</div>
                <div class="mi-value">{{angleNum[0]}}</div>
            </div>
            <div class="main-btn">
              <CommonBtn :btnText="$t('asset.btn_2')" :btnType="2"  @btnClick="openWithdraw(1, 1)"></CommonBtn>
            </div>
          </div>
          <div class="main-row">
            <div class="main-item">
                <div class="mi-key">{{$t('asset.ab')}}({{systemInfo.tkname}})</div>
                <div class="mi-value">{{abNum[0]}}</div>
            </div>
            <div class="main-btn">
              <CommonBtn :btnText="$t('asset.btn_2')" :btnType="2"  @btnClick="openWithdraw(1, 2)"></CommonBtn>
            </div>
          </div>
          
          <div class="main-row">
            <div class="main-item">
                <div class="mi-key">{{$t('asset.team')}}({{systemInfo.tkname}})</div>
                <div class="mi-value">{{teamNum[0]}}</div>
            </div>
            <div class="main-btn" v-if="systemInfo.teamti=='1'">
              <CommonBtn :btnText="$t('asset.btn_2')" :btnType="2"  @btnClick="openWithdraw(1, 3)"></CommonBtn>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="pageType==4">
        <div class="recharge-float" @click="toWithdraw">
          <CommonBtn :btnText="$t('asset.towith')" :btnType="0"></CommonBtn>
        </div>
        <div class="main-award">
          <div class="ma-key">{{$t('asset.remain')}}({{systemInfo.tkname}})</div>
          <div class="ma-value">{{ rewardData['rewardAll']??'0' }}</div>
        </div>
        <div class="reward-box">
          <div class="text1">{{$t('asset.yesreward')}}</div>
          <div class="text2">{{ rewardData['reward']??'0' }}</div>
          <div class="text3">{{ (rewardData['rate']??'0')+'%' }}</div>
        </div>
      </template>
    </div>
    <div class="record">
      <div class="record-title">{{(pageType != 4) ? $t('asset.bill'):$t('asset.rewardetail')}}</div>
      <div v-if="pageType == 4" class="record-symbol"></div>
      <div class="tab" v-if="(pageType != 4&&pageType != 0)">
        <div class="tab-item" v-for="(o, i) in typeArr" :key="i" :class="{'tab-active': logType == i}" @click="changeTabIndex(i)">
          <div class="tab-text">{{o.name}}</div>
          <div class="tab-op"></div>
        </div>
      </div>
      <van-list
        class="record-box"
        v-model:loading="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad"
      >
        <div class="record-list">
          <div class="item" v-for="(o, i) in assetList" :key="i">
            <div class="item-desc">
              <div class="item-title">{{o.title??""}}</div>
              <div class="item-time">{{o.time??""}}</div>
            </div>
            <div class="item-value" :class="o.value >= 0 ? 'color-up' : 'color-down'">{{o.value??""}}</div>
          </div>
        </div>
      </van-list>
      <NoData v-if="assetList.length == 0 && !firstLoad"></NoData>
    </div>
    <van-popup 
      v-model:show="visible"
      round
      class="ErrorContent"
    >
      <div class="content">
        <img class="header-icon" @click="close" src="@/assets/images/icon_Delete.png" alt="">
        <div class="ec-title">{{$t('asset.error_title')}}</div>
        <div class="ec-subtitle">{{$t('asset.error_subtitle')}}:</div>
        <div class="ec-table ec-head">
          <div class="ec-item1">{{$t('asset.error_item1')}}</div>
          <div class="ec-item2">{{$t('asset.error_item2')}}</div>
          <div class="ec-item3">{{$t('asset.error_item3')}}</div>
        </div>
        <div class="ec-table ec-row" v-for="(o, i) in errorData" :key="i">
          <div class="ec-item1">{{o.date}}</div>
          <div class="ec-item2">{{o.ed}}</div>
          <div class="ec-item3">{{o.update}}</div>
        </div>
      </div>
    </van-popup>
    <Withdraw ref="Withdraw" @refresh="refresh"></Withdraw>
    <TabBar :active="2"></TabBar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { MiningAccount, MiningLog, ExchangeStatus, MiningErrorApi, orderInfo, rewardInfo, RewardLog, NetworkAccount, NetworkLog } from '@/api/user'
import Withdraw from '@/components/Withdraw'
import TabBar from '@/components/TabBar'
import NoData from '@/components/NoData'
import Web3 from "web3"
import pledgeAbi from '@/utils/pledgeAbi'
import CommonBtn from '../../components/CommonBtn.vue'
export default {
  name: 'Asset',
  components: {
    Withdraw,
    TabBar,
    NoData,
    CommonBtn
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
      account: state => state.wallet.account,
      mudBalance: state => state.wallet.mudBalance,
      angleNum: state => state.wallet.angleNum,
      abNum: state => state.wallet.abNum,
      teamNum: state => state.wallet.teamNum,
      pledgeNum: state => state.wallet.pledgeNum,
      pledgeAddr: state => state.wallet.pledgeAddr,
      miningAddr: state => state.wallet.miningAddr,
      decimals: state => state.wallet.decimals,
      miningExtracted: state => state.wallet.miningExtracted,
    }),
    networkTotal() {
      if (this.angleNum[1] == '-' || this.abNum[1] == '-') {
        return '-'
      } else {
        return this.angleNum[1] + this.abNum[1]
      }
    }
  },
  watch: {
    async account() {
      if (this.account) {
        await this.checkBalance()
        await this.getMiningInfo()
        await this.getOrderInfo()
      }
    },
    pageType() {
      this.logType = 0
      if (this.pageType == 1) {
        this.getMiningInfo()
      } else if (this.pageType == 2) {
        this.getOrderInfo()
      } else if(this.pageType == 0) {
        this.checkBalance()
      }else {
        console.log("6")
      }
    }
  },
  data() {
    return {
      pageType: 2, // 1 挖矿(单币) 0 全网 2流动性 4 奖金
      assetInfo: {},
      assetList: [],
      start: 0,
      limit: 10,
      firstLoad: true,
      loading: false,
      finished: false,
      award: '-', // 可提取奖励
      pledge: '-', // 质押中
      extractable: '-', // 可提取质押数

      logType: 0, // 类型 0全部1质押2挖矿奖励3节点奖励7提取
      typeArr: [
        {
          name: this.$t('asset.tab_1')
        },
        {
          name: this.$t('asset.tab_2')
        },
        {
          name: this.$t('asset.tab_3')
        },
        {
          name: this.$t('asset.tab_4')
        },
        {
          name: this.$t('asset.tab_5')
        }
      ],
      visible: false, // 待修正弹窗
      errorData: [],
      orderData: {},

      rewardData: {},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions(['GetUserInfo', 'setNoticeType', 'checkBalance', 'miningCheckBalance']),
    async getData() {
      let req = this.pageType != 4 ? (this.pageType!=0?MiningAccount:NetworkAccount) : rewardInfo
      await req({type: this.pageType}).then(res => {
        this.assetInfo = res
      })
      if (this.account) {
        await this.GetUserInfo()
        await this.getMiningInfo()
        await this.getOrderInfo()
        await this.getRewardInfo()
      }
    },
    async getRewardInfo(){
      await rewardInfo().then(res => {
        this.rewardData = res;
      })
    },
    async getMiningInfo() {

      await this.miningCheckBalance()
      this.award = this.miningExtracted

      await this.checkBalance(4)
      this.extractable = this.pledgeNum[0]
      if (this.pledgeNum[1] == '-' || this.pledgeNum[0] == '-') {
        this.pledge = '-'
      } else {
        this.pledge = +this.pledgeNum[1] - +this.pledgeNum[0]
      }
      
    },
    async getOrderInfo() {
      await orderInfo().then(res => {
        this.orderData = res.data
      })
    },
    refresh() {
      if (this.pageType == 1) {
        this.getMiningInfo()
      } else if (this.pageType == 2) {
        this.getOrderInfo()
      } else {
        this.checkBalance()
      }
    },
    changeStatus(type) {
      this.pageType = type
      this.getData()

      this.start = 0
      this.assetList = []
      this.firstLoad = true
      this.finished = false
      this.onLoad()
    },
    async onLoad() {
      let req = this.pageType != 4 ? (this.pageType!=0?MiningLog:NetworkLog) : RewardLog
      let start = this.start
      await req({start: start, limit: this.limit, type: this.logType}).then(res => {
        if (this.firstLoad) {
          this.firstLoad = false
        }
        if (!start || start == 0) {
          this.assetList = []
        }
        this.assetList = this.assetList.concat(res.list??[])
        if (this.assetList.length >= res.count) {
          this.finished = true
        } else {
          this.finished = false
        }
        this.start = this.assetList.length
        this.loading = false
        
      })
    },
    toPledge() {
      this.$router.push('/liquidity')
    },
    toRecord() {
      this.$router.push('/record')
    },
    openWithdraw(type, status) {
      if (type == 0 && this.award == 0) {
        this.$toast(this.$t('toast.noBalance'))
        return
      }
      if (type == 1 && status == 1 && this.angleNum[0] == 0) {
        this.$toast(this.$t('toast.noBalance'))
        return
      }
      if (type == 1 && status == 2 && this.abNum[0] == 0) {
        this.$toast(this.$t('toast.noBalance'))
        return
      }
      this.$refs.Withdraw.show(type, status)
    },
    openNotice() {
      // this.setNoticeType(this.pageType != 0 ? 9 : 10)
      this.setNoticeType(7)
    },
    changeTabIndex(type) {
      this.logType = type

      this.start = 0
      this.assetList = []
      this.firstLoad = true
      this.finished = false
      this.onLoad()
    },
    toDaily() {
      this.$router.push('/daily?type=' + this.pageType)
    },
    toWithdraw(){
      this.$router.push('/withdraw');
    },
    async withdraw() {
      // 判断是否设置不可提取
      if (this.userInfo.isextractZy && this.userInfo.isextractZy != 2) {
        if (this.pledgeNum[0] == 0) {
          this.$toast(this.$t('toast.noBalance'))
          return
        }
        const web3 = new Web3(window.ethereum)
        const address = this.account
        let myContract = new web3.eth.Contract(pledgeAbi, this.pledgeAddr, {
          from: address, 
        });
        this.btnLoading = true

        await myContract.methods.Withdraw().send((error, result) => {
          console.log('Withdraw', error, result)
          if (!error) {
            ExchangeStatus({
              id: -1,
              txid: result,
              status: 2,
              value: this.pledgeNum[0],
            }).then(res => {
              console.log(res)
              this.btnLoading = false
              this.getData()
              this.$toast(this.$t('toast.withdraw'))
            })
          } else {
            this.btnLoading = false
            error = error || this.$t('toast.dealFailed')
            this.$toast(error)
          }
        });
      } else if (this.userInfo.isextractZy == 2) {
        this.$toast(this.$t('withdraw.toast_1'))
        return
      }
    },
    openDetail() {
      MiningErrorApi({type: this.pageType}).then(res => {
        this.visible = true
        this.errorData = res
        console.log(res)
      })
    },
    close() {
      this.visible = false
    },
  }
}
</script>
<style lang="scss" scoped>

.Asset {
  position: relative;
  min-height: calc(100vh - 50px);
  padding: 0 15px;
  padding-top: 40px;
  padding-bottom: 70px;
  background: url(~@/assets/images/bg.png);
  background-size: 100% auto;
  background-position: 0 -40px;
  .notice {
    position: absolute;
    right: 15px;
    top: 40px;
    width: 22px;
    height: 22px;
    background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
    border: 1px solid #282B2E;
    box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
    border-radius: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .head {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 0;
    margin-bottom: 30px;
    width: 120%;
    overflow-x: auto;
    .head-item {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      .hi-text {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #BDBDBD;
      }
      .hi-op {
        width: 44px;
        height: 5px;
        margin-top: 5px;
        background: transparent;
      }
    }
    .head-item-active {
      .hi-text {
        color: #FFFFFF;
      }
      .hi-op {
        background: #EDC071;
        box-shadow: inset 0px -1px 2px #D28B0E;
        border-radius: 25px;
      }
    }
  }
  .main {
    position: relative;
    width: 100%;
    padding: 30px 24px;
    margin-bottom: 24px;
    border-radius: 40px;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D;
    .main-award {
      margin-bottom: 10px;
      .ma-key {
        margin-bottom: 6px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
      }
      .ma-value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #FFFFFF;
      }
    }
    .main-yesterday {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      .my-key {
        margin-right: 14px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
      }
      .my-num {
        margin-right: 9px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
      }
      .my-rate {
        padding: 2px 9px;
        background: #292D32;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
        border-radius: 20px;
        color: #A5A8AA;
      }
      .my-rate-up {
        color: $color-gold;
      }
      .my-icon {
        width: 16px;
        height: 16px;
      }
    }
    .main-list {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
      .main-row {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        margin: 4px 0;
        .main-btn {
          width: 90px;
          height: 36px;
          margin-left: 20px;
        }
      }
      .main-row:not(:last-child) {
        border-bottom: 1px solid rgba($color: #999999, $alpha: 0.3);
      }
    }
    .main-item {
      flex: 1;
      .mi-key {
        margin-bottom: 8px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #A5A8AA;
      }
      .mi-value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
      }
      }
    .main-list-col {
      flex-direction: column;
    }
    .main-btns {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .main-btn {
        width: 137px;
        height: 41px;
      }
      .main-btnmain {
        width: 284px;
        height: 40px;
        margin: 10px auto;
      }
    }
  }
  .main1 {
    height: 360px;
    background: url(~@/assets/images/bg_Mining.png);
    background-size: 100% 100%;
  }
  .main2 {
    height: 320px;
    padding: 16px 24px;
    // background: url(~@/assets/images/bg_WholeNetwork.png);
    background: url(~@/assets/images/bg_Mining.png);
    background-size: 100% auto;
  }
  .main3 {
    height: 330px;
    background: url(~@/assets/images/bg_Mining.png);
    background-size: 100% auto;
  }
  .main4 {
    height: 159px;
    background: url(~@/assets/images/bg_Mining.png);
    background-size: 100% auto;
  }
  .record {
    width: 100%;
    padding: 24px 30px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    border-radius: 40px;
    .record-title {
      margin-bottom: 7px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
    .record-symbol {
      width: 29px;
      height: 6px;
      margin-bottom: 7px;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
    // .record-box {
      
    // }
    .record-list {

      .item {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 70px;
      }
      .item:not(:last-child) {
        border-bottom: 1px solid #000000;
      }
      .item-desc {
        flex: 1;
        margin-right: 20px;
      }
      .item-title {
        margin-bottom: 2px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
      }
      .item-time {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #B8B6B2;
      }
      .item-value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .tab {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(100% + 40px);
    margin: 0 -20px;
    margin-bottom: 16px;
    .tab-item {
      position: relative;
      flex: 1;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .tab-text {
      margin-bottom: 4px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #A5A8AA;
    }
    .tab-op {
      width: 28px;
      height: 4px;
      background: transparent;
    }
    .tab-active {
      .tab-text {
        color: #FFFFFF;
      }
      .tab-op {
        background: #EDC071;
        box-shadow: inset 0px -1px 2px #D28B0E;
        border-radius: 25px;
      }
    }
  }
  .main-float {
    position: absolute;
    right: 20px;
    top: 40px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 86px;
    height: 32px;
    background: #292D32;
    box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
    border-radius: 10px;
    
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    // color: #A5A8AA;
    color: #EDC67F;
  }

  .recharge-float {
    position: absolute;
    right: 20px;
    top: 40px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: 30px;
    background: #292D32;
    box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
    border-radius: 10px;
    
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    // color: #A5A8AA;
    color: #EDC67F;
  }
}
.ErrorContent {
  border-radius: 40px;
  .content {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 320px;
    min-height: 240px;
    padding: 15px 18px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
    // border-radius: 40px;
    .ec-title {
      margin-bottom: 4px;
      font-family: 'Alibaba PuHuiTi';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
    .ec-subtitle {
      margin-bottom: 10px;
      text-align: left;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #B8B6B2;
      word-break: break-all;
    }
    .ec-table {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-family: 'Roboto';
      font-style: normal;
      font-size: 13px;
    }
    .ec-head {
      color: #B8B6B2;
    }
    .ec-row {
      font-size: 12px;
      color: #FFFFFF;
    }
    .ec-item1 {
      width: 90px;
    }
    .ec-item2 {
      width: 90px;
    }
    .ec-item3 {
      flex: 1;
      text-align: right;
    }
  }
  .header-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
  }
  .main {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    color: #B8B6B2;
  }
}
.success-btns {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  margin-top: -32px;
  .success-btn {
    width: 154px;
    height: 41px;
    margin: 0 10px;
  }
}
.reward-box {
  display: flex;
  margin-top: 20px;
  align-items: center;
  .text1 {
    font-size: 13px;
    color: #A5A8AA;
  }
  .text2 {
    font-size: 16px;
    color: white;
    margin: 0 10px;
  }
  .text3 {
    font-size: 16px;
    color: #E2B25C;
    
  }
}
</style>

